import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CRICKET_NUMBERS } from '../stores/GameStore';
import Mark from './Mark';
import './Scores.css';
import PlayerForm from './PlayerForm';

class Scores extends Component {
  getOnNameChange = (playerID) => (e) => {
    this.props.gameStore.updatePlayer(playerID, {
      name: e.target.value,
    });
  }

  render() {
    const {
      gameStore: {
        addPlayer,
        avgMarksPerRound,
        canStillAddPlayer,
        currentlyPlayingID,
        removePlayer,
        playerRanks,
        playersArray,
        throws,
        scores,
      },
    } = this.props;

    return (
      <table className="Scores__table">
        <thead>
          <tr className="Scores__number-row">
            <th scope="col" className="Scores__number-first" />
            <th scope="col" className="Scores__score">
              Rank
            </th>
            <th scope="col" className="Scores__score">
              Score
            </th>
            {CRICKET_NUMBERS.map(number => (
              <th scope="col" key={number} className="Scores__number">
                {number === 25 ? <span className="fa fa-bullseye" /> : number}
              </th>
            ))}
            <th scope="col" className="Scores__score">
              Avg
            </th>
          </tr>
        </thead>
        <tbody>
          {playersArray.map((player) => {
            const playerScores = scores[player.id] || {};
            const playerRank = playerRanks[player.id];

            return (
              <tr key={player.id} className="Scores__players-container">
                <th scope="row" className="Scores__player-form">
                  <span
                    className={`fa fa-play Scores__play${
                      currentlyPlayingID === player.id ? ' is-playing' : ''}`
                    }
                  />
                  <PlayerForm
                    canDelete={!throws.length}
                    player={player}
                    onChange={this.getOnNameChange(player.id)}
                    onDelete={() => removePlayer(player.id)}
                  />
                </th>
                <td className="Scores__player-score">
                  {playerRank}
                </td>
                <td className="Scores__player-score">
                  {playerScores.score || 0}
                </td>
                {CRICKET_NUMBERS.map(number => (
                  <td key={number} className="Scores__player-mark">
                    <Mark multiple={playerScores[number] || 0} />
                  </td>
                ))}
                <td className="Scores__player-score">
                  {(avgMarksPerRound[player.id] || 0).toFixed(1).replace('.0', '')}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="Scores__add-player-container">
              {canStillAddPlayer && <button
                className="Scores__add-player-btn"
                onClick={addPlayer}
              >
                Add player <span className="fa fa-plus" />
              </button>}
            </td>
            <td colSpan="8" className="Scores__add-player-colspan" />
          </tr>
        </tfoot>
      </table>
    );
  }
}

export default inject('gameStore')(observer(Scores));

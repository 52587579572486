import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { deburr, range, words } from 'lodash';
import { CRICKET_NUMBERS } from '../stores/GameStore';
import './VoiceRecognition.css';

const CANCEL_WORDS = new Set(['annule', 'annuler', 'efface', 'effacer', 'non']);
const CENTER_WORDS = new Set(['centre', 'boule', 'bull', 'bulle', 'diana', '25']);
const OUT_WORDS = new Set(['out', 'dehors', 'rien']);
const ALL_WORDS = new Set(['tout', 'tous']);
const MULTIPLE_WORDS = {
  triple: 3,
  triples: 3,
  double: 2,
  doubles: 2,
};

const SEPARATOR = ' | ';
const NUMBERS_GRAMMAR = `#JSGF V1.0; grammar numbers; public <number> = ${
  range(1, 15).concat(CRICKET_NUMBERS).join(SEPARATOR)
} ;`;
const CANCELS_GRAMMAR = `#JSGF V1.0; grammar cancels; public <cancel> = ${
  Array.from(CANCEL_WORDS).join(SEPARATOR)
} ;`;
const CENTERS_GRAMMAR = `#JSGF V1.0; grammar centers; public <center> = ${
  Array.from(CANCEL_WORDS).join(SEPARATOR)
} ;`;
const OUTS_GRAMMAR = `#JSGF V1.0; grammar outs; public <out> = ${
  Array.from(OUT_WORDS).join(SEPARATOR)
} ;`;
const ALLS_GRAMMAR = `#JSGF V1.0; grammar alls; public <all> = ${
  Array.from(ALL_WORDS).join(SEPARATOR)
} ;`;
const MULTIPLES_GRAMMAR = `#JSGF V1.0; grammar multiples; public <multiple> = ${
  Object.keys(CRICKET_NUMBERS).join(SEPARATOR)
} ;`;

class VoiceRecognition extends Component {
  state = {
    isSpeechRecognitionAvailable: false,
    transcript: '',
    language: 'fr',
    microStarted: false,
  }

  componentDidMount() {
    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    if (SpeechRecognition) {
      this.setState({ isSpeechRecognitionAvailable: true });
      this.initSpeechRecognition(SpeechRecognition);
    }
  }

  componentWillUnmount() {
    this.recognition = null;
  }

  initSpeechRecognition(SpeechRecognition) {
    this.recognition = new SpeechRecognition();

    const SpeechGrammarList = window.webkitSpeechGrammarList || window.SpeechGrammarList;
    const speechRecognitionList = new SpeechGrammarList();
    speechRecognitionList.addFromString(NUMBERS_GRAMMAR, 1);
    speechRecognitionList.addFromString(CANCELS_GRAMMAR, 1);
    speechRecognitionList.addFromString(CENTERS_GRAMMAR, 1);
    speechRecognitionList.addFromString(OUTS_GRAMMAR, 1);
    speechRecognitionList.addFromString(ALLS_GRAMMAR, 1);
    speechRecognitionList.addFromString(MULTIPLES_GRAMMAR, 1);
    this.recognition.grammars = speechRecognitionList;

    this.recognition.interimResults = true;
    this.recognition.maxAlternatives = 1;
    this.recognition.continuous = true;
    this.recognition.lang = this.state.language;

    this.recognition.addEventListener('result', this.onRecognitionResult);
    this.recognition.addEventListener('nomatch', console.log);
    this.recognition.addEventListener('soundstart', console.log);
    this.recognition.addEventListener('soundstop', console.log);
    // this.recognition.start();
  }

  onMicroClick = () => {
    const { microStarted } = this.state;
    try {
      if (microStarted) {
        this.recognition.abort();
      } else {
        this.recognition.start();
      }
      this.setState({ microStarted: !microStarted });
    } catch(err) {
      console.error(err);
    }
  };

  onRecognitionResult = (e) => {
    const lastResult = e.results[e.results.length - 1];
    console.log({
      isFinal: lastResult.isFinal,
      results: e.results.length,
      resultIndex: e.resultIndex,
      transcriptLen: lastResult.length,
    });
    const { transcript } = lastResult[0];

    this.setState({
      transcript,
      isFinal: lastResult.isFinal,
    });

    if (!lastResult.isFinal) {
      return;
    }

    const transcriptWords = words(deburr(transcript).trim().toLowerCase());
    const {
      cancel,
      cancelRound,
      mark,
    } = this.props.gameStore;

    if (transcriptWords.find(w => CANCEL_WORDS.has(w))) {
      if (transcriptWords.find(w => ALL_WORDS.has(w))) {
        cancelRound();
        return;
      }
      cancel();
      return;
    }

    let lastMultiple = 1;
    let lastWasTen = false;
    transcriptWords.forEach(w => {
      if (w === 'x') {
        lastWasTen = true;
        return;
      }
      if (w === 'xx') {
        w = 20;
      }
      if (w === 'w') {
        w = 20;
        lastMultiple = 2;
      }
      if (CENTER_WORDS.has(w)) {
        w = 25;
      }
      if (MULTIPLE_WORDS[w]) {
        lastMultiple = MULTIPLE_WORDS[w];
        return;
      }
      const wordAsNumber = Number(w);
      if (wordAsNumber || wordAsNumber === 0) {
        const number = wordAsNumber + (lastWasTen ? 10 : 0);
        mark(number, lastMultiple);
        lastMultiple = 1; // reset
      }
      if (lastWasTen) {
        lastWasTen = false;
      }
    });
  };

  render() {
    const {
      isFinal,
      isSpeechRecognitionAvailable,
      transcript,
      microStarted,
    } = this.state;

    return !isSpeechRecognitionAvailable ? null : (
      <div className="VoiceRecognition__container">
        <button
          className={`VoiceRecognition__micro-btn${microStarted ? ' is-active' : ''}`}
          onClick={this.onMicroClick}
        >
          <span className={`fa fa-microphone${microStarted ? '-slash' : ''}`}/>
        </button>
        <cite
          className={`VoiceRecognition__transcript${isFinal || !microStarted ? ' is-final' : ''}`}
        >
          {microStarted ? transcript || 'Tell me what you scored' : 'Voice Recognition'}
        </cite>
      </div>
    );
  }
}

export default inject('gameStore')(VoiceRecognition);

import React from 'react';
import './Mark.css';

const Mark = ({ multiple = 0, number = '' , big }) => (
  <div className="Mark">
    <div className="Mark__bars-container">
      {multiple > 0 && <div className="Mark__first-bar" />}
      {multiple > 1 && <div className="Mark__second-bar" />}
    </div>
    {multiple > 2 && <div className={`Mark__circle${big ? ' is-big' : ''}`} />}
    <div className={`Mark__number${number === 25 ? ' is-bull' : ''}`}>
      {number !== 25 ? number : 'BULL'}
    </div>
  </div>
);

export default Mark;

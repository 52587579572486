import { action, computed, decorate, observable } from 'mobx';
import { forEach, fromPairs, groupBy, map, range, toPairs, sortBy, findIndex } from 'lodash';
import { v4 as uuid } from 'uuid';

export const CRICKET_NUMBERS = range(15, 21).concat([25]).reverse();

class GameStore {
  constructor() {
    const id1 = uuid();
    const id2 = uuid();
    this.players = new Map([
      [id1, { id: id1, name: 'Player 1' }],
      [id2, { id: id2, name: 'Player 2' }],
    ]);
  }

  throws = [];
  canceledThrows = [];


  get playersArray() {
    return Array.from(this.players.values());
  }


  get canStillAddPlayer() {
    return this.throws.length / 3 <= this.playersArray.length;
  }

  get canCancel() {
    return !!this.throws.length;
  }
  get canRedo() {
    return !!this.canceledThrows.length;
  }

  get currentlyPlayingID() {
    const { playersArray, canceledThrows } = this;
    if (canceledThrows.length) {
      return this.markingPlayerID;
    }
    const playerIndex = Math.floor(Math.max(0, this.throws.length - 1) / 3) % playersArray.length;

    return playersArray[playerIndex].id;
  }

  get markingPlayerID() {
    const { playersArray } = this;
    const playerIndex = Math.floor(this.throws.length / 3) % playersArray.length;

    return playersArray[playerIndex].id;
  }

  get throwsByPlayerID() {
    return groupBy(this.throws, 'playerID');
  }

  get scores() {
    const scoresByPlayerID = fromPairs(Object.keys(this.throwsByPlayerID).map(p => [p, {}]));
    const givePointsToUnclosed = (r) => {
      forEach(scoresByPlayerID, (playerScores, playerID) => {
        if (playerID === r.playerID) {
          return;
        }
        if (!playerScores[r.number] || playerScores[r.number] < 3) {
          const pointsToAdd = r.number * r.multiple;
          playerScores['score'] = (playerScores['score'] || 0) + pointsToAdd;
        }
      });
    };
    this.throws.forEach(r => {
      if (!CRICKET_NUMBERS.includes(r.number)) {
        return;
      }
      const playerScores = scoresByPlayerID[r.playerID];
      if (!playerScores[r.number]) {
        playerScores[r.number] = r.multiple;
        return;
      }
      const oldScore = playerScores[r.number];
      playerScores[r.number] += r.multiple;

      if (playerScores[r.number] > 3) {
        givePointsToUnclosed({
          ...r,
          multiple: playerScores[r.number] - Math.max(3, oldScore),
        });
      }
    });

    return scoresByPlayerID;
  }

  get playerRanks() {
    const scoresByPlayerID = map(this.scores, ({ score }, playerID) => [playerID, score || 0]);
    const ranking = sortBy(scoresByPlayerID, ([, score]) => score);

    return fromPairs(ranking.map(([playerID]) => {
      return [playerID, findIndex(ranking, ([pID]) => pID === playerID) + 1];
    }));
  }

  get avgMarksPerRound() {
    return fromPairs(map(
      this.scores,
      (playerScores, playerID) => {
        const totalMarks = toPairs(playerScores).reduce((acc, [number, multiple]) => {
          const isValidNumber = CRICKET_NUMBERS.includes(parseInt(number, 10));

          return acc + (isValidNumber ? multiple : 0);
        }, 0);

        return [playerID, totalMarks / this.roundNumber];
      },
    ));
  }

  get roundNumber() {
    return Math.floor(this.throws.length / (3 * this.playersArray.length || 1)) + 1;
  }

  get hasPlayed() {
    return !!this.throws.length;
  }

  get currentRound() {
    return this.throws.slice(-3).filter(r => r.playerID === this.currentlyPlayingID);
  }

  mark = (number, multiple = 1) => {
    if (!number && number !== 0) {
      return;
    }
    if (!CRICKET_NUMBERS.includes(number)) {
      number = 0;
      multiple = 0;
    }
    this.throws.push({
      id: uuid(),
      playerID: this.markingPlayerID,
      number,
      multiple,
    });
    this.canceledThrows = [];
  }

  cancel = () => {
    if (this.throws.length) {
      this.canceledThrows.push(this.throws.pop());
    }
  }
  redo = () => {
    if (this.canceledThrows.length) {
      this.throws.push(this.canceledThrows.pop());
    }
  }

  cancelRound = () => {
    if (this.currentRound.length) {
      this.throws = this.throws.slice(0, -this.currentRound.length)
      this.canceledThrows.concat(this.throws.slice(-this.currentRound.length));
    } else {
      this.cancel();
    }
  }


  addPlayer = () => {
    if (!this.canStillAddPlayer) {
      alert('Can’t add player after first round');
      return;
    }
    const id = uuid();
    this.players.set(id, {
      id,
      name: `Player ${this.playersArray.length + 1}`,
    });
  }

  updatePlayer = (id, player) => {
    const oldPlayer = this.players.get(id);
    if (oldPlayer) {
      this.players.set(id, {
        ...oldPlayer || {},
        ...player,
        id,
      });
    }
  }

  removePlayer = (id) => {
    this.players.delete(id);
  }
}

decorate(GameStore, {
  throws: observable,
  canceledThrows: observable,
  players: observable,

  canCancel: computed,
  canRedo: computed,
  canStillAddPlayer: computed,
  currentlyPlayingID: computed,
  playersArray: computed,
  roundNumber: computed,
  scores: computed,
  throwsByPlayerID: computed,

  addPlayer: action,
  cancel: action,
  cancelRound: action,
  mark: action,
  updatePlayer: action,
  removePlayer: action,
  redo: action,
});

export default GameStore;

import React from 'react';
import { observer } from 'mobx-react';
import './PlayerForm.css';

const PlayerForm = ({
  canDelete,
  player,
  onChange,
  onDelete,
}) => (
  <div className="PlayerForm">
    <input
      className="PlayerForm__input"
      onChange={onChange}
      id={`player-${player.id}`}
      value={player.name}
    />
    <div className="PlayerForm__buttons-container">
      <label
        htmlFor={`player-${player.id}`}
        className="PlayerForm__btn-edit"
        >
        <span className="fa fa-pencil" />
      </label>
      {canDelete && <button
        className={`PlayerForm__btn-delete${!canDelete ? ' is-disabled' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          onDelete(player.id);
        }}
      >
        <span className="fa fa-remove" />
      </button>}
    </div>
  </div>
);

export default observer(PlayerForm);

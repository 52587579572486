import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './Dartboard.css';


class Dartboard extends Component {
  getOnSectorClick = (number, multiple = 1) => () => {
    this.props.gameStore.mark(number, multiple);
  };

  render() {
    const textNumberProps = {
      className: 'Dartboard__board-number',
      transform: 'matrix(1 0 0 1 226.5 226.5)',
      dominantBaseline: 'text-before-edge',
      onClick: this.getOnSectorClick(0),
    };

    return (
      <svg viewBox="0 0 453 453">
        <path
          className="Dartboard__out is-black"
          d="M226.5 453C101.4 453 0 351.6 0 226.5 0 101.4 101.4 0 226.5 0 351.6 0 453 101.4 453 226.5 453 351.6 351.6 453 226.5 453Z"
          onClick={this.getOnSectorClick(0)}
        />

        <text x="-16.9" y="-215.8" {...textNumberProps}>
          20
        </text>
        <text x="51.9" y="-208.4" {...textNumberProps}>
          1
        </text>
        <text x="100" y="-178.5" {...textNumberProps}>
          18
        </text>
        <text x="153.2" y="-131.7" {...textNumberProps}>
          4
        </text>
        <text x="173.9" y="-73.8" {...textNumberProps}>
          13
        </text>
        <text x="187.2" y="-18.8" {...textNumberProps}>
          6
        </text>
        <text x="171.6" y="39.5" {...textNumberProps}>
          10
        </text>
        <text x="142.7" y="99.1" {...textNumberProps}>
          15
        </text>
        <text x="106.1" y="141.9" {...textNumberProps}>
          2
        </text>
        <text x="42.7" y="168.6" {...textNumberProps}>
          17
        </text>
        <text x="-6.1" y="180.2" {...textNumberProps}>
          3
        </text>
        <text x="-74.2" y="171" {...textNumberProps}>
          19
        </text>
        <text x="-123.7" y="145.3" {...textNumberProps}>
          7
        </text>
        <text x="-174.9" y="99.1" {...textNumberProps}>
          16
        </text>
        <text x="-193.6" y="44.9" {...textNumberProps}>
          8
        </text>
        <text x="-214.6" y="-19.2" {...textNumberProps}>
          11
        </text>
        <text x="-208.5" y="-76.8" {...textNumberProps}>
          14
        </text>
        <text x="-165.1" y="-137.5" {...textNumberProps}>
          9
        </text>
        <text x="-134.3" y="-180.2" {...textNumberProps}>
          12
        </text>
        <text x="-67.5" y="-206.7" {...textNumberProps}>
          5
        </text>

        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M198.9 57.3C216.6 54.5 234.6 54.5 252.2 57.3 250.5 68.5 241.6 124.6 225.6 225.7 211.3 135.9 202.4 79.7 198.9 57.3Z"
          onClick={this.getOnSectorClick(20, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M201.7 74.9C217.5 72.4 233.6 72.4 249.4 74.9 247.8 85.1 239.9 135.8 225.6 227.2 212.9 146 204.9 95.2 201.7 74.9Z"
          onClick={this.getOnSectorClick(20)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M208.7 119.5C219.9 117.7 231.2 117.7 242.4 119.5 241.3 126.6 235.7 162 225.6 225.7 216.6 169.1 211 133.7 208.7 119.5Z"
          onClick={this.getOnSectorClick(20, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M211.2 134.6C220.7 133.1 230.4 133.1 240 134.6 239 140.6 234.2 171 225.6 225.7 217.9 177.1 213.1 146.7 211.2 134.6Z"
          onClick={this.getOnSectorClick(20)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M252.7 57.3C270.4 60.1 287.5 65.7 303.5 73.8 298.3 84 272.5 134.6 226 225.8 240.3 136 249.2 79.8 252.7 57.3Z"
          onClick={this.getOnSectorClick(1, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M250 75C265.8 77.5 281.1 82.4 295.3 89.7 290.6 98.9 267.4 144.7 225.6 227.2 238.6 146 246.7 95.3 250 75Z"
          onClick={this.getOnSectorClick(1)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M242.9 119.6C254 121.3 264.8 124.8 274.9 130 271.6 136.4 255.3 168.3 226 225.8 235 169.1 240.6 133.7 242.9 119.6Z"
          onClick={this.getOnSectorClick(1, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M240.5 134.7C250 136.2 259.3 139.2 267.9 143.6 265.1 149 251.2 176.4 226 225.8 233.8 177.2 238.6 146.8 240.5 134.7Z"
          onClick={this.getOnSectorClick(1)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M304 74C319.9 82.2 334.5 92.8 347.1 105.4 339.1 113.4 298.9 153.6 226.5 226 267.8 145 293.6 94.3 304 74Z"
          onClick={this.getOnSectorClick(18, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M295.9 90C310.2 97.2 323.2 106.7 334.4 118 327.2 125.3 290.9 161.7 225.6 227.2 263.1 154 286.5 108.3 295.9 90Z"
          onClick={this.getOnSectorClick(18)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M275.4 130.2C285.4 135.3 294.6 142 302.6 150 297.5 155 272.2 180.4 226.5 226 252.6 174.9 268.8 143 275.4 130.2Z"
          onClick={this.getOnSectorClick(18, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M268.5 143.8C277.1 148.2 284.9 153.9 291.8 160.7 287.4 165.1 265.7 186.8 226.5 226 248.9 182.2 262.9 154.8 268.5 143.8Z"
          onClick={this.getOnSectorClick(18)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M347.4 105.5C360 118.1 370.6 132.7 378.7 148.6 368.6 153.8 318 179.6 226.8 226.1 291.1 161.7 331.3 121.5 347.4 105.5Z"
          onClick={this.getOnSectorClick(4, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M334.8 118.1C346.1 129.4 355.6 142.4 362.8 156.7 353.6 161.4 307.9 184.8 225.6 226.9 283.8 168.9 320.2 132.7 334.8 118.1Z"
          onClick={this.getOnSectorClick(4)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M302.8 150C310.8 158 317.5 167.2 322.6 177.2 316.2 180.5 284.3 196.8 226.8 226.1 267.3 185.5 292.7 160.2 302.8 150Z"
          onClick={this.getOnSectorClick(4, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M292.1 160.8C298.9 167.7 304.6 175.5 309 184.1 303.5 186.9 276.1 200.9 226.8 226.1 261.6 191.3 283.4 169.5 292.1 160.8Z"
          onClick={this.getOnSectorClick(4)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M379 149.1C387.1 165 392.6 182.1 395.4 199.8 384.2 201.6 328.1 210.5 227 226.5 308 185.2 358.7 159.4 379 149.1Z"
          onClick={this.getOnSectorClick(13, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M363.1 157.2C370.3 171.5 375.3 186.8 377.8 202.6 367.6 204.2 316.9 212.3 225.6 227 298.9 189.8 344.7 166.5 363.1 157.2Z"
          onClick={this.getOnSectorClick(13)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M322.8 177.7C327.9 187.7 331.5 198.5 333.2 209.7 326.1 210.8 290.7 216.4 227 226.5 278.1 200.5 310 184.2 322.8 177.7Z"
          onClick={this.getOnSectorClick(13, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M309.2 184.6C313.6 193.2 316.6 202.5 318.1 212 312.1 213 281.7 217.8 227 226.5 270.9 204.2 298.3 190.2 309.2 184.6Z"
          onClick={this.getOnSectorClick(13)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M395.5 200.3C398.3 218 398.3 236 395.5 253.6 384.3 251.9 328.1 243 227.1 227 316.9 212.7 373.1 203.8 395.5 200.3Z"
          onClick={this.getOnSectorClick(6, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M377.9 203.1C380.4 218.9 380.4 235 377.9 250.8 367.7 249.2 317 241.3 225.6 227 306.8 214.3 357.6 206.3 377.9 203.1Z"
          onClick={this.getOnSectorClick(6)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M333.3 210.1C335.1 221.3 335.1 232.6 333.3 243.8 326.2 242.7 290.8 237.1 227.1 227 283.7 218 319.1 212.4 333.3 210.1Z"
          onClick={this.getOnSectorClick(6, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M318.2 212.6C319.7 222.1 319.7 231.8 318.2 241.4 312.1 240.4 281.8 235.6 227.1 227 275.7 219.3 306.1 214.5 318.2 212.6Z"
          onClick={this.getOnSectorClick(6)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M395.4 254.1C392.6 271.8 387.1 288.9 379 304.8 368.8 299.7 318.2 273.9 227 227.4 316.8 241.6 373 250.5 395.4 254.1Z"
          onClick={this.getOnSectorClick(10, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M377.8 251.4C375.3 267.1 370.3 282.4 363.1 296.7 353.9 292 308.1 268.8 225.6 226.9 306.8 240 357.5 248.1 377.8 251.4Z"
          onClick={this.getOnSectorClick(10)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M333.2 244.2C331.5 255.4 327.9 266.2 322.8 276.2 316.4 273 284.5 256.7 227 227.4 283.6 236.4 319.1 242 333.2 244.2Z"
          onClick={this.getOnSectorClick(10, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M318.1 241.9C316.6 251.4 313.6 260.7 309.2 269.3 303.8 266.5 276.3 252.5 227 227.4 275.6 235.1 306 240 318.1 241.9Z"
          onClick={this.getOnSectorClick(10)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M378.7 305.5C370.6 321.5 360 336 347.4 348.7 339.3 340.7 299.1 300.5 226.8 228.1 307.8 269.4 358.5 295.2 378.7 305.5Z"
          onClick={this.getOnSectorClick(15, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M362.8 297.5C355.6 311.7 346.1 324.7 334.8 336 327.5 328.8 291.1 292.5 225.6 227.2 298.8 264.7 344.5 288.1 362.8 297.5Z"
          onClick={this.getOnSectorClick(15)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M322.6 276.9C317.5 287 310.8 296.2 302.8 304.1 297.8 299.1 272.4 273.7 226.8 228.1 277.9 254.1 309.8 270.4 322.6 276.9Z"
          onClick={this.getOnSectorClick(15, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M309 270C304.6 278.6 298.9 286.5 292.1 293.3 287.7 289 265.9 267.2 226.8 228.1 270.6 250.5 298 264.4 309 270Z"
          onClick={this.getOnSectorClick(15)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M347.1 348.8C334.5 361.4 319.9 372 304 380.1 298.8 370 273 319.3 226.5 228.2 290.8 292.5 331 332.7 347.1 348.8Z"
          onClick={this.getOnSectorClick(2, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M334.4 336.2C323.2 347.5 310.2 356.9 295.9 364.2 291.2 355 267.8 309.3 225.6 226.9 283.7 285.2 319.9 321.6 334.4 336.2Z"
          onClick={this.getOnSectorClick(2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M302.6 304.2C294.6 312.2 285.4 318.9 275.4 324 272.1 317.6 255.8 285.7 226.5 228.2 267.1 268.7 292.4 294.1 302.6 304.2Z"
          onClick={this.getOnSectorClick(2, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M291.8 293.4C284.9 300.3 277.1 306 268.5 310.4 265.7 304.9 251.7 277.5 226.5 228.2 261.3 263 283.1 284.7 291.8 293.4Z"
          onClick={this.getOnSectorClick(2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M303.5 380.3C287.5 388.5 270.4 394 252.7 396.8 250.9 385.6 242 329.4 226 228.4 267.3 309.4 293.1 360.1 303.5 380.3Z"
          onClick={this.getOnSectorClick(17, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M295.3 364.4C281.1 371.7 265.8 376.7 250 379.2 248.4 369 240.2 318.3 225.6 226.9 262.8 300.3 286 346.1 295.3 364.4Z"
          onClick={this.getOnSectorClick(17)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M274.9 324.2C264.8 329.3 254 332.8 242.9 334.6 241.7 327.5 236.1 292.1 226 228.4 252.1 279.5 268.3 311.4 274.9 324.2Z"
          onClick={this.getOnSectorClick(17, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M267.9 310.6C259.3 315 250 318 240.5 319.5 239.5 313.4 234.7 283.1 226 228.4 248.4 272.2 262.3 299.6 267.9 310.6Z"
          onClick={this.getOnSectorClick(17)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M252.2 396.9C234.6 399.7 216.6 399.7 198.9 396.9 200.7 385.7 209.6 329.5 225.6 228.4 239.8 318.3 248.7 374.4 252.2 396.9Z"
          onClick={this.getOnSectorClick(3, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M249.4 379.3C233.6 381.8 217.5 381.8 201.7 379.3 203.3 369.1 211.3 318.3 225.6 226.9 238.3 308.2 246.2 358.9 249.4 379.3Z"
          onClick={this.getOnSectorClick(3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M242.4 334.7C231.2 336.4 219.9 336.4 208.7 334.7 209.9 327.6 215.5 292.2 225.6 228.4 234.5 285.1 240.2 320.5 242.4 334.7Z"
          onClick={this.getOnSectorClick(3, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M240 319.6C230.4 321.1 220.7 321.1 211.2 319.6 212.1 313.5 216.9 283.1 225.6 228.4 233.2 277.1 238 307.4 240 319.6Z"
          onClick={this.getOnSectorClick(3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M198.5 396.8C180.8 394 163.7 388.5 147.8 380.3 152.9 370.2 178.7 319.5 225.2 228.4 211 318.2 202.1 374.4 198.5 396.8Z"
          onClick={this.getOnSectorClick(19, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M201.2 379.2C185.4 376.7 170.2 371.7 155.9 364.4 160.6 355.3 183.8 309.4 225.6 226.9 212.6 308.1 204.5 358.9 201.2 379.2Z"
          onClick={this.getOnSectorClick(19)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M208.4 334.6C197.2 332.8 186.4 329.3 176.4 324.2 179.6 317.8 195.9 285.9 225.2 228.4 216.2 285 210.6 320.4 208.4 334.6Z"
          onClick={this.getOnSectorClick(19, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M210.7 319.5C201.2 318 191.9 315 183.3 310.6 186.1 305.1 200.1 277.7 225.2 228.4 217.5 277 212.6 307.4 210.7 319.5Z"
          onClick={this.getOnSectorClick(19)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M147.3 380.1C131.4 372 116.8 361.4 104.2 348.8 112.2 340.7 152.4 300.5 224.8 228.2 183.5 309.2 157.7 359.9 147.3 380.1Z"
          onClick={this.getOnSectorClick(7, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M155.4 364.2C141.1 356.9 128.1 347.5 116.8 336.2 124.1 328.9 160.4 292.5 225.6 226.9 188.2 300.1 164.8 345.9 155.4 364.2Z"
          onClick={this.getOnSectorClick(7)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M175.9 324C165.9 318.9 156.7 312.2 148.7 304.2 153.8 299.1 179.1 273.8 224.8 228.2 198.7 279.3 182.4 311.2 175.9 324Z"
          onClick={this.getOnSectorClick(7, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M182.8 310.4C174.2 306 166.4 300.3 159.5 293.4 163.9 289.1 185.6 267.3 224.8 228.2 202.4 272 188.4 299.4 182.8 310.4Z"
          onClick={this.getOnSectorClick(7)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M103.9 348.4C91.2 335.8 80.6 321.2 72.5 305.3 82.6 300.1 133.3 274.3 224.5 227.8 160.1 292.1 119.9 332.3 103.9 348.4Z"
          onClick={this.getOnSectorClick(16, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M116.4 335.7C105.1 324.5 95.7 311.5 88.4 297.2 97.6 292.5 143.3 269.1 225.7 226.9 167.4 285 131 321.2 116.4 335.7Z"
          onClick={this.getOnSectorClick(16)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M148.4 303.9C140.4 295.9 133.7 286.7 128.6 276.7 135 273.4 167 257.1 224.5 227.8 183.9 268.4 158.5 293.7 148.4 303.9Z"
          onClick={this.getOnSectorClick(16, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M159.2 293.1C152.3 286.2 146.6 278.4 142.2 269.8 147.7 267 175.1 253 224.5 227.8 189.6 262.6 167.9 284.4 159.2 293.1Z"
          onClick={this.getOnSectorClick(16)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M72.3 304.8C64.1 288.9 58.6 271.8 55.8 254.1 67 252.3 123.1 243.4 224.2 227.4 143.2 268.7 92.5 294.5 72.3 304.8Z"
          onClick={this.getOnSectorClick(8, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M88.1 296.7C80.9 282.4 75.9 267.1 73.4 251.4 83.6 249.7 134.3 241.6 225.6 226.9 152.3 264.1 106.5 287.4 88.1 296.7Z"
          onClick={this.getOnSectorClick(8)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M128.4 276.2C123.3 266.2 119.8 255.4 118 244.2 125.1 243.1 160.5 237.5 224.2 227.4 173.1 253.5 141.2 269.7 128.4 276.2Z"
          onClick={this.getOnSectorClick(8, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M142 269.3C137.6 260.7 134.6 251.4 133.1 241.9 139.2 240.9 169.5 236.1 224.2 227.4 180.4 249.7 152.9 263.7 142 269.3Z"
          onClick={this.getOnSectorClick(8)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M55.7 253.6C52.9 235.9 52.9 217.9 55.7 200.3 66.9 202 123.1 210.9 224.1 226.9 134.3 241.2 78.2 250.1 55.7 253.6Z"
          onClick={this.getOnSectorClick(11, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M73.3 250.8C70.8 235 70.8 218.9 73.3 203.1 83.5 204.7 134.3 212.7 225.6 226.9 144.4 239.7 93.6 247.6 73.3 250.8Z"
          onClick={this.getOnSectorClick(11)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M117.9 243.8C116.2 232.6 116.2 221.3 117.9 210.1 125 211.2 160.4 216.9 224.1 226.9 167.5 235.9 132.1 241.5 117.9 243.8Z"
          onClick={this.getOnSectorClick(11, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M133 241.3C131.5 231.8 131.5 222.1 133 212.5 139.1 213.5 169.5 218.3 224.1 226.9 175.5 234.6 145.1 239.4 133 241.3Z"
          onClick={this.getOnSectorClick(11)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M72.5 148.9C80.6 133 91.2 118.4 103.9 105.7 111.9 113.8 152.1 154 224.5 226.3 143.4 185 92.8 159.2 72.5 148.9Z"
          onClick={this.getOnSectorClick(9, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M88.4 157C95.7 142.7 105.1 129.7 116.4 118.4 123.7 125.7 160.1 161.9 225.7 227.2 152.5 189.7 106.7 166.3 88.4 157Z"
          onClick={this.getOnSectorClick(9)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M128.6 177.5C133.7 167.5 140.4 158.3 148.4 150.3 153.5 155.4 178.8 180.7 224.5 226.3 173.3 200.3 141.4 184 128.6 177.5Z"
          onClick={this.getOnSectorClick(9, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M142.2 184.4C146.6 175.8 152.3 167.9 159.2 161.1 163.5 165.5 185.3 187.2 224.5 226.3 180.6 204 153.2 190 142.2 184.4Z"
          onClick={this.getOnSectorClick(9)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M104.2 105.4C116.8 92.8 131.4 82.2 147.3 74 152.5 84.2 178.3 134.8 224.8 226 160.4 161.7 120.2 121.5 104.2 105.4Z"
          onClick={this.getOnSectorClick(12, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M116.8 118C128.1 106.7 141.1 97.2 155.4 90 160.1 99.1 183.5 144.9 225.6 227.2 167.6 169 131.4 132.6 116.8 118Z"
          onClick={this.getOnSectorClick(12)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M148.7 150C156.7 142 165.9 135.3 175.9 130.2 179.2 136.6 195.5 168.5 224.8 226 184.2 185.4 158.9 160.1 148.7 150Z"
          onClick={this.getOnSectorClick(12, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M159.5 160.7C166.4 153.9 174.2 148.2 182.8 143.8 185.6 149.3 199.6 176.7 224.8 226 190 191.2 168.2 169.4 159.5 160.7Z"
          onClick={this.getOnSectorClick(12)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-red"
          d="M55.8 200.1C58.6 182.4 64.1 165.3 72.3 149.3 82.4 154.5 133 180.3 224.2 226.8 134.4 212.5 78.2 203.6 55.8 200.1Z"
          onClick={this.getOnSectorClick(14, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M73.4 202.8C75.9 187 80.9 171.7 88.2 157.5 97.3 162.1 143.1 185.4 225.6 227.2 144.5 214.2 93.7 206.1 73.4 202.8Z"
          onClick={this.getOnSectorClick(14)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-red"
          d="M118 209.9C119.8 198.8 123.3 188 128.4 177.9 134.8 181.2 166.7 197.5 224.2 226.8 167.6 217.8 132.2 212.2 118 209.9Z"
          onClick={this.getOnSectorClick(14, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-black"
          d="M133.1 212.3C134.6 202.7 137.6 193.5 142 184.9 147.5 187.7 174.9 201.6 224.2 226.8 175.6 219 145.2 214.2 133.1 212.3Z"
          onClick={this.getOnSectorClick(14)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-double is-green"
          d="M147.8 73.8C163.7 65.7 180.8 60.1 198.5 57.3 200.3 68.6 209.2 124.7 225.2 225.8 183.9 144.7 158.1 94.1 147.8 73.8Z"
          onClick={this.getOnSectorClick(5, 2)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M155.9 89.7C170.2 82.4 185.4 77.5 201.2 75 202.9 85.1 211 135.9 225.6 227.2 188.5 153.9 165.2 108.1 155.9 89.7Z"
          onClick={this.getOnSectorClick(5)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-triple is-green"
          d="M176.4 130C186.4 124.8 197.2 121.3 208.4 119.6 209.5 126.6 215.1 162.1 225.2 225.8 199.1 174.7 182.9 142.7 176.4 130Z"
          onClick={this.getOnSectorClick(5, 3)}
        />
        <path
          className="Dartboard__sector Dartboard__sector-single is-beige"
          d="M183.3 143.6C191.9 139.2 201.2 136.2 210.7 134.7 211.7 140.7 216.5 171.1 225.2 225.8 202.9 181.9 188.9 154.5 183.3 143.6Z"
          onClick={this.getOnSectorClick(5)}
        />
        <path
          className="Dartboard__bull is-green"
          d="M225.6 252.2C211.8 252.2 200.6 241 200.6 227.2 200.6 213.4 211.8 202.2 225.6 202.2 239.4 202.2 250.6 213.4 250.6 227.2 250.6 241 239.4 252.2 225.6 252.2Z"
          onClick={this.getOnSectorClick(25)}
        />
        <path
          className="Dartboard__bull is-red"
          d="M225.6 237.7C219.8 237.7 215.1 233 215.1 227.2 215.1 221.4 219.8 216.7 225.6 216.7 231.4 216.7 236.1 221.4 236.1 227.2 236.1 233 231.4 237.7 225.6 237.7Z"
          onClick={this.getOnSectorClick(25, 2)}
        />
      </svg>
    );
  }
}

export default inject('gameStore')(observer(Dartboard)
);

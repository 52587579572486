import React from 'react';
import './App.css';
import CurrentRound from './CurrentRound';
import Dartboard from './Dartboard';
import Scores from './Scores';

function App() {
  return (
    <div className="App">
      <div className="App__dartboard-container">
        <Dartboard />
      </div>
      <div className="App__scores-container">
        <CurrentRound />
        <div>
          <Scores />
        </div>
      </div>
    </div>
  );
}

export default App;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { range } from 'lodash';
import Mark from './Mark.js';
import './CurrentRound.css';
import VoiceRecognition from './VoiceRecognition.js';


class CurrentRound extends Component {
  render() {
    const {
      gameStore: {
        cancel,
        canCancel,
        canRedo,
        currentRound,
        roundNumber,
        redo,
      },
    } = this.props;

    return (
      <div className="CurrentRound">
        <div className="CurrentRound__scores-title">
          <div className="CurrentRound__buttons-container">
            <button
              className="CurrentRound__button-cancel"
              disabled={!canCancel}
              onClick={cancel}
            >
              <span className="fa fa-undo" />
            </button>
            <button
              className="CurrentRound__button-redo"
              disabled={!canRedo}
              onClick={redo}
            >
              <span className="fa fa-repeat" />
            </button>
          </div>
          <h2 className="CurrentRound__round-title">
            Round: {roundNumber}/20{' '}
          </h2>
          <div className="CurrentRound__voice-recognition-container">
            <VoiceRecognition />
          </div>
        </div>
        <ol className="CurrentRound__player-scores">
          {range(0, 3).map(i => {
            const round = currentRound[i] || {};
            return (
              <li key={round.id || i} className="CurrentRound__round">
                <Mark big {...round || {}} />
              </li>
            );
          })}
        </ol>
      </div>
    );
  }
}

export default inject('gameStore')(observer(CurrentRound));

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import './variables.css';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import stores from './stores';

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <App />
    </Provider>
    <link
      rel="stylesheet"
      href="//stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// confirm on close
window.addEventListener('beforeunload', function (e) {
  e.preventDefault();
  e.returnValue = '';
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
